.container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 10px 10px; 
    grid-template-areas: 
      "input1 input2 input3"; 
    justify-content: center; 
    margin:40px;
  }
  .input2 { grid-area: input2; }
  .input1 { grid-area: input1; }
  .input3 { grid-area: input3; }





  .containerTitle {  display: grid;
    margin-left: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-columns: 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    justify-content: start;
    justify-items: start;
    align-items: center;
    grid-template-areas:
      "title";
  }
  
  .title { grid-area: title; }
  

  .thickDivider {
    height: 0.2650rem;

  }



.crearsorteobtn { grid-area: crearsorteobtn;margin-left: 30px;margin:5px; }
.cancelar-sorteo-btn { grid-area: cancelar-sorteo-btn;margin-left: 30px;margin:5px; }



.containerButtons {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  justify-content: end; 
  justify-items: end; 
  margin:50px;
}
.finalButtons { grid-area: 1 / 1 / 2 / 2; }
.container-sorteos {
  display: grid; 
  grid-template-columns: 0.8fr 1.2fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px;
  justify-content: center;
  justify-items: center;
  grid-template-areas: 
    "main latest"; 
}
.main { grid-area: main; 
margin-top:40px;
margin-left:30px;}
.latest { grid-area: latest;
margin-top:40px; }


.containerButtons {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  justify-content: end; 
  justify-items: end; 
  margin:50px;
}
.finalButtons { grid-area: 1 / 1 / 2 / 2; }


.centerAll{
  text-align: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
 
}

.container-exclusion {  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  grid-template-rows: 0.7fr 0.6fr;
  grid-auto-columns: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-items: stretch;
  grid-template-areas:
    "drops details"
    ". buttons";
}

.details {  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "text"
    "date-pickers";
  grid-area: details;
  justify-self: center;
}

.text { grid-area: text;  justify-self: center; margin-top: 40px; }

.date-pickers { grid-area: date-pickers;   justify-self: center;margin-top: 20px;}

.drops { grid-area: drops; justify-self: center;margin-top:40px; }



.buttons {  justify-self: end; 
  align-self: center; 
  grid-area: buttons;
  margin-right: 50px;}

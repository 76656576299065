.deshabilitadoFont {
  color: #767676;
  font-weight: 400;
}

.deshabilitadoIconColor {
  color: #9f0e0e;
}
.habilitadoIconColor {
  color: #0e9f0e;
}


.titularTableSubTitle {
    color: #5d9032;
    font-weight: 600;
  }

  .detailTableMargins {
    margin-top: 40px;
    margin-bottom: 30px;
    
  }

  .listEstadosSorteo{
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
.container_data_table {  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    "tipo_sorteo origen estado"
    "fecha- caratula anulado"
    "extra extra1 extra2";
}

.tipo_sorteo { grid-area: tipo_sorteo; }

.origen { grid-area: origen; }

.estado { grid-area: estado; }

.fecha- { grid-area: fecha-; }

.caratula { grid-area: caratula;
width: 400px;
}

.anulado { grid-area: anulado; }

.extra { grid-area: extra; }

.extra1 { grid-area: extra1; }

.extra2 { grid-area: extra2; }

.label-wrapper { display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%; }




  #cssportal-grid {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    width: 100%;
    height: 100%;
  }
  #div1 {
    grid-area: 1 / 1 / 2 / 2;
  
    justify-self: center;
    align-self: center;
  }
  #div2 {
    grid-area: 1/2/2/3;
   
    align-self: center;
    justify-self: center;
  }
  #div3 {
    grid-area: 1/3/2/4;
   
    align-self: center;
    justify-self: center;
  }
  #div4 {
    grid-area: 2/1/3/2;
    
    align-self: center;
    justify-self: center;
  }
  #div5 {
    grid-area: 2/2/3/3;
   
    justify-self: center;
    align-self: center;
  }
  #div6 {
    grid-area: 2/3/3/4;
   
    justify-self: center;
    align-self: center;
  }
  #div7 {
    grid-area: 3/1/4/2;
   
    align-self: center;
    justify-self: center;
  }
  #div8 {
    grid-area: 3/2/4/3;
   
    justify-self: center;
    align-self: center;
  }
  #div9 {
    grid-area: 3/3/4/4;
   
    justify-self: center;
    align-self: center;
  }
  #div10 {
    grid-area: 4/1/5/2;
   
    justify-self: center;
    align-self: center;
  }
  #div11 {
    grid-area: 4/2/5/3;
   
    justify-self: center;
    align-self: center;
  }
  #div12 {
    grid-area: 4/3/5/4;
   
    justify-self: center;
    align-self: center;
  }
  #div13 {
    grid-area: 5/1/6/2;
   
    justify-self: center;
    align-self: center;
  }
  #div14 {
    grid-area: 5/2/6/3;
   
    align-self: center;
  }
  #div15 {
    grid-area: 5/3/6/4;
   
    align-self: center;
  }
.detail-container {  display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-columns: 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    justify-content: start;
    justify-items: center;
    align-items: center;
    grid-template-areas:
      "top"
      "sub-detail";
    
  }
  
  .top { grid-area: top; }
  
  .sub-detail {  display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    justify-content: space-around;
    justify-items: stretch;
    align-items: center;
    grid-template-areas:
      "a b c"
      "d e f"
      "g h i";
    grid-area: sub-detail;
  }
  
  .a { grid-area: a; }
  
  .b { grid-area: b; }
  
  .c { grid-area: c; }
  
  .d { grid-area: d; }
  
  .e { grid-area: e; }
  
  .f { grid-area: f; }
  
  .g { grid-area: g; }
  
  .h { grid-area: h; }
  
  .i { grid-area: i; }
  

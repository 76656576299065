.container_filters {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto; /* Change to auto for dynamic row sizing */
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "filters_left filters_right"
    "options_left options_right";
  justify-content: space-evenly;
  justify-items: center;
  align-content: space-evenly;
  align-items: center;
}

.filters_left {
  grid-area: filters_left;
  display: flex; /* Change to flex to control alignment */
  flex-direction: column; /* Arrange children in a column */
}

/* Adjust margins for better spacing */
.filters_left > * {
  margin-bottom: 15px;
}

.filters_right {
  grid-area: filters_right;
  display: flex; /* Change to flex to control alignment */
  flex-direction: column; /* Arrange children in a column */
}

/* Adjust margins for better spacing */
.filters_right > * {
  margin-bottom: 15px;
}

.options_left {
  grid-area: options_left;
}

.options_right {
  grid-area: options_right;
}

.container-crud {  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  justify-content: start;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    "nombrecrud"
    "observacioncrud"
    "otroscrud"
    "botonescrud";
}

.nombrecrud { grid-area: nombrecrud; }

.observacioncrud { grid-area: observacioncrud; }

.otroscrud { grid-area: otroscrud;
margin-top: 0px;
margin-bottom:30px; 
margin-left:10px;
justify-self: flex-end !important;}

.botonescrud { grid-area: botonescrud; }

.dialogcrud {  
  max-width: 50% !important;
}

.botonesaccionescrud{
  display: flex;
  justify-content: space-between;
}

.botonDeshabilitar{
  float:right;
  margin-left: 10px;
  margin-bottom: 30px;
}

.fueroName{
  margin-bottom: 30px;
  text-align: center;
}
.sre-selection-container {  display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: 1.3fr 0.7fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "judgeSelector selectedDetail"
      ". ."
      ". buttons";
  }
  
  .judgeSelector { grid-area: judgeSelector;}
  
  .buttons { grid-area: buttons; }
  
  .selectedDetail { grid-area: selectedDetail; }
  
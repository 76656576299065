.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.dialog-card {
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);  /* Increased shadow */
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
    font-weight: bold;  /* Made the text bold */
}

button:hover {
    background-color: #0056b3;
}
